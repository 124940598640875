<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong>Reporte Clientes</strong>
          </CCardHeader>
          <CCardBody>

          <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
            <b-row class="justify-content-center">

              

                <b-col sm="2" >
                  <b-form-group label="Actvidad Económica:">
                    <b-form-input placeholder="Todos" v-model="report.economic_activity"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Ubigeo:">
                    <v-select placeholder="- Todos -" label="text" class="w-100" v-model="report.ubigee" :options="ubigee"></v-select>
                  </b-form-group>
                </b-col>

                <b-col sm="2" >
                  <b-form-group label="Edad desde:">
                    <b-form-input type="number" class="text-center" v-model="report.from_age"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col sm="2" >
                  <b-form-group label="Edad Hasta:">
                    <b-form-input type="number" class="text-center" v-model="report.to_age"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col sm="2" >
                  <b-form-group label="Total Rango de Edades:">
                    <b-form-input disabled class="text-center" v-model="report.total_age_range"></b-form-input>
                  </b-form-group>
                </b-col>


                <b-col sm="12" md="1">
                  <b-form-group>
                    <label for="">.</label>
                    <b-button class="form-control" type="submit" variant="primary"><i class="fas fa-search"></i></b-button>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="1">
                  <b-form-group>
                    <label for="">.</label>
                    <b-button class="form-control" type="button" variant="success" @click="ExportExcel"><i class="fas fa-file-excel"></i></b-button>
                  </b-form-group>
                </b-col>

                

            </b-row>
          </b-form>


              <div class="table-responsive mt-3">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <th class="text-center" colspan="9">CLIENTES</th>
                    </tr>
                    <tr>
                      <th class="text-center">#</th>
                      <th class="text-center">Nombres y Apellidos</th>
                      <th class="text-center">Dirección</th>
                      <th class="text-center">DNI</th>
                      <th class="text-center">Fecha Nacimiento</th>
                      <th class="text-center">Edad</th>
                      <th class="text-center">Actvidad Economica</th>
                      <th class="text-center">Celular</th>
                      <th class="text-center">Ubigeo</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr v-for="(item, it) in data_table" :key="it">
                      <td class="text-center">{{ it + 1 }}</td>
                      <td class="text-left">{{ item.name  }}</td>
                      <td class="text-left">{{ item.address }}</td>
                      <td class="text-left">{{ item.document_number }}</td>
                      <td class="text-center">{{ item.birth_date }}</td>
                      <td class="text-center">{{ item.age }}</td> 
                      <td class="text-left">{{ item.profession }}</td> 
                      <td class="text-left">{{ item.cell_phone }}</td> 
                      <td class="text-left">{{ item.ubigee_value }}</td> 
                    </tr>
                  </tbody>
                </table>
              </div>
  
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<style>
        table {
            width: 100%;
            border-collapse: collapse;
            overflow-x: auto; 
        }
    </style>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import LoadingComponent from './../pages/Loading'

export default {
  name: "UsuarioList",
  components:{
    vSelect,
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'ReportClients',
      role:1,
      perPage: 15,
      currentPage: 1,
      rows: 0,
      data_table: [],
   
      report:{
        economic_activity:'',
        ubigee: null,
        code_ubigee:'',
        from_age:18,
        to_age:65,
        total_age_range:0,
      },
      economic_activity : [],
      ubigee: [],
      age_range:[],
      total_age_range:[],
  
      errors:{
        id_user:false,
        to:false,
      }
    };
  },
  mounted() {
   this.ListUbigeos();
  },
  methods: {
    ListUbigeos,
    Validate,
    Report,
    ExportExcel,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    id_establishment: function () {
      let id_establishment = window.localStorage.getItem("id_establishment");
      id_establishment = JSON.parse(je.decrypt(id_establishment));
      return id_establishment;
    },
  },
};


function ListUbigeos() {
  this.ubigee = [];
  let url = this.url_base + "list-ubigee";
  let me = this;
  axios({
      method: "GET",
      url: url,
    }).then(function (response) {
      for (let index = 0; index < response.data.length; index++) {
      const element = response.data[index];
      me.ubigee.push({value:element.id_ubigee,text:element.department+', '+element.province+', '+element.district })
    }
  })
}

function ExportExcel() {  

  let code_ubigee = this.report.ubigee == null ? 'all': this.report.ubigee.value;

  let me = this;
  me.report.economic_activity = me.report.economic_activity.length == 0 ? 'all': me.report.economic_activity;
  let url = me.url_base + "excel/report-clients/"+me.report.economic_activity+"/"+code_ubigee+"/"+me.report.from_age+"/"+me.report.to_age;
  window.open(url,'_blank');
}

function Validate() {
  this.Report();
}


function Report() {
  let me = this;
  me.report.code_ubigee = me.report.ubigee == null ? 'all': me.report.ubigee;
  let data = me.report;
  let url = this.url_base + "report/clients";
  me.isLoading = true;
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module, role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.data_table = response.data.result.clients;
        me.report.total_age_range = response.data.result.total;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
     .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}





</script>
